:root {
  --source-sans: "Source Sans 3", sans-serif;
  --poppins: "Poppins", sans-serif;
  --oswald: "Oswald", sans-serif;
  --damion: "Damion", cursive;
}
.nav-link {
  color: #fff !important;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: var(--source-sans);
}
.lottie-loader {
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: #000d0e;
}
.lottie-loader div {
  width: 300px;
}
.nav-item a {
  color: #fff;
}
.nav-item .active {
  color: #fff;
}
.background {
  background: #000d0e;
  height: 140vh;
}
.button-il {
  color: #000d0e;
  font-family: var(--poppins);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* banner section */

.common-container {
  padding: 2% 5%;
}
.banner-text {
  color: #fff;
  font-family: var(--oswald);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}

.main-text {
  background: linear-gradient(90deg, #00c0d4 9.4%, #80d3b6 91.17%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--oswald);
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 96px */
  text-transform: uppercase;
}
.backdrop-card {
  border-radius: 24px;
  opacity: 0.1;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.6) 100%
    ),
    lightgray -88.047px -82.958px / 150.255% 142.942% no-repeat;
  width: 28vw;
  height: 60vh;
  transform: rotate(15deg);
  flex-shrink: 0;
  position: relative;
}

.backdrop-card-1 {
  transform: rotate(30deg) !important;
}
.backdrop-card-2 {
  transform: rotate(45deg) !important;
}
.backdrop-card-3 {
  transform: rotate(75deg) !important;
}
.backdrop-card-4 {
  transform: rotate(60deg) !important;
}
.slider-container {
  /* margin-top: 6%; */
  position: relative;
  top: 40%;
}
.mask-gradient {
  background: linear-gradient(180deg, #000d0e 0%, rgba(0, 13, 20, 0) 100%);
  position: absolute;
  z-index: 10;
  bottom: 0px;
  width: 100%;
  rotate: 180deg;
  height: 10vh;
}
.vhi-30 {
  height: 50vh;
}

.hide-nav {
  padding-top: 7%;
}
.description {
  color: rgba(255, 255, 255, 0.68);
  text-align: center;
  font-family: var(--source-sans);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
}
.rotating-circle {
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  background-color: #00c0d4;
  border-radius: 50%;
  top: 25%;
  right: 5%;
}
.stage-section {
  padding: 2% 5%;
}
.stg-heading {
  font-family: var(--oswald);
  font-size: 200px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  background: linear-gradient(180deg, #fff 0%, #f6feff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rocket-btn {
  width: 100px;
  height: 100px;
  right: 20%;
}

.rocket-btn img {
  opacity: 0;
  transition: all 1s;
}
.rocket-btn:hover img {
  opacity: 1;
  transform: translateY(0px);
}
.calender-component {
  border-bottom: 0px;
}
.calender-component h1 {
  color: rgba(255, 255, 255, 0.84);
  font-family: var(--oswald);
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 115.2px */
}
.month-container {
  border-top: 2px solid white;
}
.month-container p {
  color: #fff;
  text-align: center;
  font-family: var(--source-sans);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 164.286% */
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.value-card {
  height: 65vh;
  width: 30vw;
  background: rgba(0, 192, 212, 0.07);
}
.corner-styler {
  background: rgba(0, 192, 212, 0.37);
  width: 70px;
  height: 10vh;
}
.card-title {
  color: #fff;

  font-family: var(--oswald);
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 115.2px */
  text-transform: uppercase;
}
.value-card h2 {
  font-size: 40px !important;
}
.card-content-container {
  background: rgba(255, 255, 255, 0.13);
  width: 30vw;
  height: 65vh;
}
.card-content-container p {
  color: #fff;
  font-family: var(--damion);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 48px */
}
.carousel-btn div {
  width: 50px;
  height: 5px;
  background-color: #fff;
}
.card-animation-section img {
  position: absolute;
}
.card-animation-section {
  height: 60vh;
  align-items: center;
}
.card-animation-section img {
  transition: all 1.6s;
}
.glitch:hover .card-card1 {
  rotate: -5deg;
}
.glitch:hover .card-card2 {
  rotate: 5deg;
}

.spread .card-card0 {
  transform: rotate(-31deg) translate3d(-50%, -10%, 0);
  box-shadow: 0 1px 2px 0 rgba(151, 150, 146, 0.4),
    0 4px 12px 0 rgba(151, 150, 146, 0.4);
}

.spread .card-card1 {
  transform: rotate(-4.5deg) translatex(-22%);
  box-shadow: 0 1px 2px 0 rgba(151, 150, 146, 0.4),
    0 2px 6px 0 rgba(151, 150, 146, 0.4);
}
.spread .card-card2 {
  transform: rotate(5.8deg) translatex(10%);
  box-shadow: 0 1px 2px 0 rgba(151, 150, 146, 0.4),
    0 2px 4px 0 rgba(151, 150, 146, 0.4);
}
.spread .card-card3 {
  transform: rotate(22.5deg) translate3d(40%, -5%, 0);
  box-shadow: 0 1px 2px 0 rgba(151, 150, 146, 0.4),
    0 2px 4px 0 rgba(151, 150, 146, 0.4);
}
.spread .card-card4 {
  transform: rotate(34.5deg) translate3d(60%, -5%, 0);
  box-shadow: 0 1px 2px 0 rgba(151, 150, 146, 0.4),
    0 2px 4px 0 rgba(151, 150, 146, 0.4);
}

.footer {
  background: #162222;
}
.footer-container h4 {
  color: #fff;
  font-family: var(--oswald);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.footer-container p {
  color: #e8e8e8;
  font-family: var(--source-sans);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 149%; /* 29.8px */
}
.footer-container a {
  text-decoration: none;
  color: #00c0d4;
  font-family: var(--source-sans);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.footer-container h6 {
  color: #fff;
  font-family: var(--oswald);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.footer-container .small-text {
  color: #fff;
  font-family: var(--source-sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}
.smallText {
  color: #dfdfdf;
  font-family: var(--source-sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}
.pop-modal {
  border-radius: 23px;
  background: rgba(255, 255, 255, 0.16);
  padding: 20px;
}
.pop-modal h4 {
  color: #fff;
  font-family: var(--oswald);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  text-transform: uppercase;
}
.pop-modal ul {
  margin-left: 0px;
  padding-left: 15px;
  margin-top: 3%;
}
.pop-modal li {
  color: #e9e9e9;
  font-family: var(--source-sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
}
.body-of-contact h1 {
  font-family: var(--oswald);
  font-size: 60px;
  text-align: center;
  color: #fff;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.body-of-contact h4 {
  color: var(--Footer, #fff);
  font-family: var(--oswald);
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 57.6px */
  width: 70%;
}
.body-of-contact {
  padding: 4% 5%;
}
.body-of-contact p {
  color: var(--Footer, #fff);
  font-family: var(--source-sans);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
  width: 70%;
  margin-top: 3vh;
  letter-spacing: 1px;
}
.form-container input {
  border-radius: 3px;
  border: 1px solid #575757;
  background: rgba(255, 255, 255, 0.21);
  padding: 10px 20px;
  color: #fff;
  font-family: var(--source-sans);
  font-size: 14px;
  outline: none;
}
.form-container textarea {
  border-radius: 3px;
  border: 1px solid #575757;
  background: rgba(255, 255, 255, 0.21);
  padding: 10px 20px;
  color: #fff;
  font-family: var(--source-sans);
  font-size: 14px;
  outline: none;
}
.form-container label {
  margin-top: 18px;
  font-family: var(--source-sans);
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.success h2 {
  font-family: var(--oswald);
  color: #00c0d4;
  font-size: 60px;
}
.success button {
  font-family: var(--source-sans) !important;
  font-weight: 500;
}
@media screen and (max-width: 1440px) {
  .card-title {
    font-size: 70px;
  }
  .banner-text {
    font-size: 30px;
  }
  .value-card {
    width: 40vw;
  }
  .main-text {
    font-size: 50px;
  }
  .card-content-container p {
    font-size: 28px;
  }
  .card-content-container {
    width: 40vw;
  }
}

.handle_dynamic_height{
  height: 100vh;
}
@media screen and (max-width: 740px) {
  .hide-nav {
    padding-top: 25%;
  }
  .stg-heading {
    font-size: 40px;
  }
  .handle_dynamic_height{
    height: 100%;
  }
  .rotating-circle {
    width: 85px;
    height: 85px;
  }
  .rotating-circle img:nth-child(2) {
    width: 70px;
    height: 70px;
  }
  .rotating-circle img:nth-child(1) {
    width: 20px;
  }
  .description {
    display: none;
  }
  .rocket-btn {
    width: 50px;
    height: 50px;
  }
  .calender-component h1 {
    font-size: 30px;
  }
  .card-content-container {
    width: 75vw;
    height: auto;
   margin-left: auto;
   margin-right: auto;
  }
  .value-card {
    width: 75vw;
height: 30vh;
   margin-left: auto;
   margin-right: auto;
  }
  .card-content-container p{
    font-size: 19px;
  }
  .corner-styler {
    height: 100px;
  }
  .value-card img{
    height: 200px;
    width: 200px;
  }
  .value-card h2{
    font-size: 25px !important;
  }
.value-card{
  height: 40vh;
}
  .card-title{
    font-size: 50px;
  }
  .card-animation-section img{
    width: 270px;
  }
  .card-animation-section{
    height:40vh;
  }
}
